import index from './apis/index'
import user from './apis/user.js'
import product from './apis/product.js'
import opublic from './apis/public'

export default {
  ...index,
  ...user,
  ...product,
  ...opublic
}