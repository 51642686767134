<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"/>
    <div class="errorDialogContainer">
      <el-dialog
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :fullscreen="true"
          :show-close="false"
          :visible.sync="dialogVisible"
          title="提示">
        <span class="colorB4272B" style="font-size: 30px;">接口地址错误，请等待更新</span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import websiteUrl from "@/api/wangZhi";

export default {
  data() {
    return {
      dialogVisible: false
    };
  },
  mounted() {
    if ((location.host.indexOf('.com') !== -1 && websiteUrl === 'https://jpapi.rakumart.cn') || (location.host.indexOf('.cn') !== -1 && websiteUrl === 'https://apiwww.rakumart.com')) {
      this.dialogVisible = true
    }
  },
  methods: {
    fanyi() {
      localStorage.removeItem("fanyi");
      this.$forceUpdate();
    }
  },
};
</script>
<style lang="scss">
@import "./css/mixin";

html,
body,
#app {
  background-color: #f6f6f6;
  // height: 100%;
  font-family: "Open Sans", Sans-serif;
}

/deep/ .el-input__inner {
  font-family: "Open Sans", Sans-serif;
  color: pink !important;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
}

.errorDialogContainer .el-dialog__body {
  padding: 0 !important;
  text-align: center;
  height: calc(100vh - 54px);
  line-height: calc(100vh - 54px);
}

.poinDone {
  pointer-events: none;

  & *,
  *,
  *::before {
    pointer-events: none;
  }
}

input[type="checkbox"] {
  $checkBoxSize: 16px;
  -webkit-appearance: none;
  width: $checkBoxSize;
  height: $checkBoxSize;
  border-radius: 4px;
  border: 1px solid #888888;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    background: $homePageSubjectColor;
    border: none;
  }

  &:checked::after {
    content: "";

    position: absolute;
    background: transparent;
    border: #fff solid 1.5px;
    border-top: none;
    border-right: none;
    height: 2px;
    width: 40%;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

button {
  cursor: pointer;
  transition: 0.3s;
  background-color: transparent;
}

.anniu {
  border: solid;
  position: fixed;
  width: 100px;
  height: 100px;
  left: 0px;
  z-index: 55999999;
}

// 去除弹出框背景颜色等
.el-popover.el-popper.notHaveBackground {
  background-color: transparent;
  box-shadow: none;
  border: none;
  margin-top: 0;
  padding: 0;

  .popper__arrow {
    display: none;
  }
}

// 禁用状态下的组件字体颜色
.is-disabled .el-input__inner {
  color: #666666;
}
</style>
<style>
#cnzz_stat_icon_1281134212 {
  opacity: 0;
  height: 0;
}
</style>
