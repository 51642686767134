import Vue from 'vue'
import OSS from 'ali-oss';
import apis from '../api/apis.js'

let clientInstance = ''

let timeout = null // 创建一个标记用来存放定时器的返回值
let canRun = true;

export default {
  toCommodityDetails: (data, type) => {
    let href = "/ProductDetails?goods_id=" + data + '&fromPlatform=' + type;
    window.open(href, "_blank");
    //let href = "/ProductDetails?goods_id=" + data + '&fromPlatform=' + type;
    // window.open(href, "_blank");
    // location.reload()
    // router.push({
    //     path: "/ProductDetails",
    //     query: {
    //         goods_id: data
    //     }
    // });
    // location.reload()
    // let routeUrl = router.resolve({
    //     path: "/ProductDetails",
    //     query: {
    //         goods_id: data,
    //         fromPlatform: type
    //     }
    // });
    // window.open(routeUrl.href);
  },
  getUploadToken: async () => {
    const res = await apis.getUploadToken()
    let data = res.data
    clientInstance = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: 'oss-ap-northeast-1',
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: data.AccessKeyId,
      accessKeySecret: data.AccessKeySecret,
      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: data.SecurityToken,
      refreshSTSToken: async () => {
        // 向您搭建的STS服务获取临时访问凭证。
        const info = await fetch('your_sts_server')
        return {
          accessKeyId: info.accessKeyId,
          accessKeySecret: info.accessKeySecret,
          stsToken: info.stsToken,
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒。
      refreshSTSTokenInterval: 300000,
      // 填写Bucket名称。
      bucket: 'rakumart-ps20',
    })
  },
  async startUpload(uploadtype, rawFile, uploadProgressCallback) {
    await this.getUploadToken();
    let code = '';
    for (var i = 0; i < 9; i++) {
      code += parseInt(Math.random() * 10);
    }
    var date = new Date();
    const year = date.getFullYear()
    var month = date.getMonth() + 1;  //取得日期中的月份，其中0表示1月，11表示12月
    if (month < 10) {
      month = '0' + month
    }
    // 假设mathNum()是一个生成随机数的函数，这里需要确保它在外部定义或作为参数传入
    let name = rawFile.name // 注意：这里假设rawFile有一个uid属性
    let formData = new FormData()
    formData.append('file', rawFile)
    // 构建目标路径（如果需要的话，可以在uploadConfig中传入自定义的路径）
    const targetPath =
     '/dest/' +
     year +
     month +
     '/' +
     code +
     '/' +
     name;
    if (uploadtype == 'put') {
      return new Promise((resolve, reject) => {
        clientInstance.put(targetPath, rawFile).then(function (imgOBJ) {
          // 通知调用者上传成功
          resolve(imgOBJ)
        })
        .catch(function (err) {
          // 通知调用者上传失败
          reject(err)
        })
      })
    } else {
      let _this = this;
      return new Promise((resolve, reject) => {
        clientInstance.multipartUpload(targetPath, rawFile, {
          progress: function (percentage) {
            let percentagenum = Math.round(percentage * 100)
            uploadProgressCallback(rawFile, percentagenum)
          },
          partSize: 10 * 1024 * 1024, // 指定分片大小
        })
        .then(function (imgOBJ) {
          // 通知调用者上传成功
          if (imgOBJ.res.requestUrls[0]) {
            imgOBJ.res.requestUrls[0] = _this.removeUploadIdFromUrl(
             imgOBJ.res.requestUrls[0]
            )
          }
          resolve(imgOBJ)
        })
        .catch(function (err) {
          // 通知调用者上传失败
          reject(err)
        })
      })
    }
  },
  //防止事件冒泡的点击事件
  removeEventBubbling() {
  
  },
  removeUploadIdFromUrl(url) {
    // 使用正则表达式匹配 ?uploadId= 后面的所有内容，包括 ?uploadId= 本身
    // 注意这里使用了非贪婪匹配（*?）来确保只匹配第一个 ?uploadId= 后面的内容
    // 如果你的 URL 中可能有多个 ?uploadId=，并且你只想移除第一个，那么这个正则表达式是合适的
    // 如果你想要移除所有，那么可能需要使用其他方法或更复杂的正则表达式
    const regex = /\?uploadId=.*$/
    // 使用 replace 方法替换匹配到的部分为空字符串
    return url.replace(regex, '')
  },
  mathNum() {
    var num = ''
    for (var i = 0; i < 3; i++) {
      num += Math.floor(Math.random() * 10)
    }
    return num
  },
  newToCommodityDetails: (data, shopType, is, type) => {
    let href = "/ProductDetails?goods_id=" + data + '&fromPlatform=' + shopType + '&recommendedGoods=' + is + '&source=' + type;
    window.open(href, "_blank");
  },
  toPage: (url) => {
    location.href = url
  },
  isJSONString(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  },
  statisticsNumberOfOrders(arr, type) {
    let numInfo = {
      num1: 0,//通过词搜下单的商品个数
      num2: 0,//通过图搜下单的商品个数
      num3: 0,//通过链搜下单的商品个数
      num4: 0,//通过分类搜下单的商品个数
      num5: 0,//通过首页爆品榜单下单的商品个数
      num6: 0,//通过搜索（词搜、图搜、链搜、品类搜）下单的商品个数
      num7: 0,//通过主页商品下单的商品个数
      num8: 0,//通过其他途径下单的商品个数
      num9: 0,//通过1688跨境选品助手下单并付款的商品个数
    }
    arr.forEach((item) => {
      if (this.isJSONString(item.trace)) {
        if (item.trace != undefined && item.trace != '') {
          let trace = JSON.parse(item.trace);
          if (trace.type == 'index' && trace.isJxhy == true) {
            numInfo.num5++
            numInfo.num7++
          } else {
            if (trace.isJxhy == true) {
              numInfo.num6++
            }
            switch (trace.type) {
              case 'link':
                numInfo.num3++
                break;
              case 'classify':
                numInfo.num4++
                break;
              case 'img':
                numInfo.num2++
                break;
              case 'index':
                numInfo.num7++
                break;
              case 'keyword':
                numInfo.num1++
                break;
              case 'other':
                numInfo.num8++
                break;
              case 'LandingPage':
                numInfo.num9++
                break;
            }
          }
        }
      }
    })
    if (location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1) {
      if (numInfo.num1 > 0) {
        __bl.sum(type == 'send' ? '通过词搜下单的商品个数统计' : '通过词搜下单并付款的商品个数统计', numInfo.num1);
      }
      if (numInfo.num2 > 0) {
        __bl.sum(type == 'send' ? '通过图搜下单的商品个数统计' : '通过图搜下单并付款的商品个数统计', numInfo.num2);
      }
      if (numInfo.num3 > 0) {
        __bl.sum(type == 'send' ? '通过链搜下单的商品个数统计' : '通过链搜下单并付款的商品个数统计', numInfo.num3);
      }
      if (numInfo.num4 > 0) {
        __bl.sum(type == 'send' ? '通过分类搜下单的商品个数统计' : '通过分类搜下单并付款的商品个数统计', numInfo.num4);
      }
      if (numInfo.num5 > 0) {
        __bl.sum(type == 'send' ? '通过首页爆品榜单下单的商品个数统计' : '通过首页爆品榜单下单并付款的商品个数统计', numInfo.num5);
      }
      if (numInfo.num6 > 0) {
        __bl.sum(type == 'send' ? '通过搜索（词搜、图搜、链搜、品类搜）下单的商品个数统计' : '通过搜索（词搜、图搜、链搜、品类搜）下单并付款的商品个数统计', numInfo.num6);
      }
      if (numInfo.num7 > 0) {
        __bl.sum(type == 'send' ? '通过主页商品下单的商品个数统计' : '通过主页商品下单并付款的商品个数统计', numInfo.num7);
      }
      if (numInfo.num8 > 0) {
        __bl.sum(type == 'send' ? '通过其他途径下单的商品个数统计' : '通过其他途径下单并付款的商品个数统计', numInfo.num8);
      }
      if (numInfo.num9 > 0) {
        __bl.sum(type == 'send' ? '通过1688跨境选品助手下单的商品个数统计' : '通过1688跨境选品助手下单并付款的商品个数统计', numInfo.num8);
      }
      if (type != 'send') {
        localStorage.removeItem('paySubmitGoodsList')
      }
    }
  },
  //返回处理好的汇率
  returnServiceRate(val) {
    if (val != 0.010 && val != 0.020 && val != 0.030 && val != 0.040 && val != 0.050 && val != 0) {
      return (Number(val) * 100).toFixed(1)
    } else {
      return Number(val) * 100
    }
  },
  unlink(arr) {
    for (var i = 0; i < arr.length; i++) {    // 首次遍历数组
      for (var j = i + 1; j < arr.length; j++) {   // 再次遍历数组
        if (arr[i] == arr[j]) {          // 判断连个值是否相等
          arr.splice(j, 1);           // 相等删除后者
          j--;
        }
      }
    }
    return arr
  },
  setToolValue(value) {
    let k = 'wakanda forever!@9523`';
    let s = `${k}${value}${k}`;
    return Vue.prototype.$dfgdfdee(s)
  },
  setUserID() {
    let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
    let str = `rakumart-${userData.operation_id}`;
    return Vue.prototype.$dfgdfdee(str)
  },
  priceReplace(e) {
    let sNum = e.toString(); //先转换成字符串类型
    sNum = sNum.replace(/１/g, '1')
    sNum = sNum.replace(/２/g, '2')
    sNum = sNum.replace(/３/g, '3')
    sNum = sNum.replace(/４/g, '4')
    sNum = sNum.replace(/５/g, '5')
    sNum = sNum.replace(/６/g, '6')
    sNum = sNum.replace(/７/g, '7')
    sNum = sNum.replace(/８/g, '8')
    sNum = sNum.replace(/９/g, '9')
    sNum = sNum.replace(/０/g, '0')
    if (sNum.indexOf(".") < 0 && sNum != "") {
      sNum = parseFloat(sNum);
    }
    return sNum
  },
  isOfficialWeb() {
    return location.host.indexOf('.co.jp') !== -1 || location.host.indexOf('.com') !== -1;
  },
  routerToPage: (url, isBlank) => {
    //router.push(url);
    location.href = url
    // location.reload()
  },
  unique: (arr) => { //数组去重
    return Array.from(new Set(arr))
  },
  convertImageToBase64(src) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(src);
    });
  },
  exportFile(data, type, fileName) {
    const blob = new Blob([data], {
      // type类型后端返回来的数据中会有，根据自己实际进行修改
      // 表格下载为 application/xlsx，压缩包为 application/zip等，
      type: type
    })
    const filename = fileName
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename)
    } else {
      var blobURL = window.URL.createObjectURL(blob)
      // 创建隐藏<a>标签进行下载
      var tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', filename)
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)// 移除dom元素
      window.URL.revokeObjectURL(blobURL)// 释放bolb内存
    }
  },
  roundFixed(num) {//修改js四舍五入
    if (Number.isFinite(num)) {//判断是否为数字
      //使用 ES6 中新增的数字方法 toLocaleString() 中提供的 maximumFractionDigits 参数，如：
      let result = num.toLocaleString(undefined, {maximumFractionDigits: 2});
      //小数部分位数不够时补零
      let float_part = result.split('.')[1];
      //如果小数部分无值，直接补零
      if (!float_part) {
        let zero_str = (Math.pow(10, 2) + '').split('1')[1];//要补的零
        result = result + '.' + zero_str;
        return result;
      }
      //如果小数位数不够，补缺少的零
      if (float_part.length < 2) {
        let float_part_length = float_part.length;
        let zero_str = (Math.pow(10, 2 - float_part_length) + '').split('1')[1];//要补的零
        result = result + zero_str;//小数部分已有小数点，不需再拼小数点
        return result;
      }
      return result;//正常的情况，直接返回
    } else {//不是数字
      return '';
    }
  },
  routerToOrderDetailsPage(item) {
    let href = "/OrderDetails?order_sn=" + item;
    window.open(href, "_blank");
  },
  routerToDeliveryDetailsPage(item) {
    let href = "/deliveryDetails?pOrder_sn=" + item;
    window.open(href, "_blank");
  },
  //将相同skuId的数量进行重置
  mergeArrayValue(arr) {
    let end = [];
    end.push(arr[0]);
    if (arr.length > 0) {
      for (let i = 1; i < arr.length; i++) {
        arr[i].num = Number(arr[i].num);
        let j = 0
        for (; j < end.length; j++) {
          // 6. 判断两个数组中的id是否相同
          if (arr[i].skuId === end[j].skuId) {
            // 7.相等就将我们比较的对象 `arr[i]` 存储到  相同id对象的list 中
            end[j].num = arr[i].num;
            // *** 8.这里要理解，我们每次遍历，其实都是处理  `arr[i]` ，而上面的逻辑已经处理了，所以终止后续的循环 break;
            break;
          }
        }
        if (j === end.length) {
          end.push(arr[i])
        }
      }
    }
    return end
  },
  //去除一个数组中与另一个数组中的值相同的元素
  quChu: (a, b) => { //数组去重
    let c = []
    for (let i of a) {
      if (b.indexOf(i) === -1) {
        c.push(i)
      }
    }
    return c
  },
  //   将数组转换为onum个一组
  changeArr: (oarr, onum) => {
    const arr = oarr
    const len = arr.length
    let result = []
    const sliceNum = onum // 这个值代表几个一组
    
    for (let i = 0; i < len / sliceNum; i++) {
      result.push(arr.slice(i * sliceNum, (i + 1) * sliceNum))
    }
    // 补足不足onum个的最后一个数组
    // for(let j = 0; j < (sliceNum - len % sliceNum); j++) {
    //     result[result.length - 1].push({})
    // }
    
    return result
  },
  //   传入店铺类型和店铺类型的汇率数组，返回汇率
  getServiceRate: (arr, type) => {
    let value = '';
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].type == type) {
        value = Number(arr[i].rate);
        return value
      }
    }
  },
  /**
   * 函数防抖
   * 触发事件后在n秒后执行，如果n秒内又触发事件，则重新计算时间
   */
  debounce(fn, wait = 1000) {
    let timer;
    return function () {
      let context = this;
      let args = arguments;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(context, args);
      }, wait)
    }
  },
  /**
   * 函数节流
   * 触发事件立即执行，但在n秒内连续触发则不执行
   */
  throttle(fn, wait, options = {}) {
    let timer;
    let previous = 0;
    let throttled = function () {
      let now = +new Date();
      // remaining 不触发下一次函数的剩余时间
      if (!previous && options.leading === false) previous = now;
      let remaining = wait - (now - previous);
      if (remaining < 0) {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        previous = now;
        fn.apply(this, arguments)
      } else if (!timer && options.trailing !== false) {
        timer = setTimeout(() => {
          previous = options.leading === false ? 0 : new Date().getTime();
          timer = null;
          fn.apply(this, arguments);
        }, remaining);
      }
    }
    return throttled;
  },
  /*
  * 从已知日期或当前日期开始计算下一个月的日期
  * 通过 getNextMoth("yyyy-MM-dd") 或 getNextMoth() 调用方法
  * nowDate 格式为 "yyyy-MM-dd"
  * 返回值类型格式为 "yyyy-MM-dd"
  * */
  getNextMoth() {
    let date, year, month, day;
    date = new Date(),
     year = date.getFullYear(),
     month = date.getMonth() + 1,
     day = '01';
    let b_year, b_month, b_day;
    if (month === 12) { // 如果为 12 月，进一年
      b_year = year + 1;
    } else {
      b_year = year;
    }
    let b_month_arr = [3, 5, 8, 10];
    if (b_month_arr.includes(month) && day === 31) { // 如果月份是大月，则要处理 31 号
      b_month = month + 2;
      b_day = 1;
    } else if (month === 1 && ((year % 4 === 0 && year % 100 != 0)
     || year % 400 === 0) && day >= 29) { // 若是闰年 1 月，则要处理大于 29 号的日子
      if (day === 29) {
        b_month = month + 1;
        b_day = day;
      } else {
        b_month = month + 2;
        b_day = day % 29;
      }
    } else if (month === 1 && !((year % 4 === 0 && year % 100 != 0)
     || year % 400 === 0) && day >= 28) { // 若不是闰年且是 1 月，则要处理大于 28 号的日子
      if (day === 29) {
        b_month = month + 1;
        b_day = day;
      } else {
        b_month = month + 2;
        b_day = day % 28;
      }
    } else {
      b_month = month + 1 > 12 ? (month + 1) % 12 : month + 1;
      b_day = day;
    }
    let t_month = b_month + "";
    if (b_month < 10) { // 如果月份小于 10，在其前面加上 0
      t_month = "0" + t_month;
    }
    if (month < 10) { // 如果月份小于 10，在其前面加上 0
      month = "0" + month;
    }
    let t_day = b_day + "";
    if (b_day < 10) { // 如果日期小于 10，在其前面加上 0
      t_day = "0" + t_day;
    }
    if (day < 10) { // 如果日期小于 10，在其前面加上 0
      day = "0" + day;
    }
    let b_Date = (b_year + "-" + t_month + "-" + t_day).toString();
    let a_Date = (year + "-" + month + "-" + day).toString();
    return {
      b_Date: b_Date,
      a_Date: a_Date
    }
  },
  // 加密字符串
  toCode(str) { //加密字符串
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var a = key.split(""); //把密钥字符串转换为字符数组
    var s = "",
     b, b1, b2, b3; //定义临时变量
    for (var i = 0; i < str.length; i++) { //遍历字符串
      b = str.charCodeAt(i); //逐个提取每个字符，并获取Unicode编码值
      b1 = b % l; //求Unicode编码值得余数
      b = (b - b1) / l; //求最大倍数
      b2 = b % l; //求最大倍数的于是
      b = (b - b2) / l; //求最大倍数
      b3 = b % l; //求最大倍数的余数
      s += a[b3] + a[b2] + a[b1]; //根据余数值映射到密钥中对应下标位置的字符
    }
    return s; //返回这些映射的字符
  },
  // 解密字符串
  fromCode(str) {
    //定义密钥，36个字母和数字
    var key = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var l = key.length; //获取密钥的长度
    var b, b1, b2, b3, d = 0,
     s; //定义临时变量
    s = new Array(Math.floor(str.length / 3)); //计算加密字符串包含的字符数，并定义数组
    b = s.length; //获取数组的长度
    for (var i = 0; i < b; i++) { //以数组的长度循环次数，遍历加密字符串
      b1 = key.indexOf(str.charAt(d)); //截取周期内第一个字符串，计算在密钥中的下标值
      d++;
      b2 = key.indexOf(str.charAt(d)); //截取周期内第二个字符串，计算在密钥中的下标值
      d++;
      b3 = key.indexOf(str.charAt(d)); //截取周期内第三个字符串，计算在密钥中的下标值
      d++;
      s[i] = b1 * l * l + b2 * l + b3 //利用下标值，反推被加密字符的Unicode编码值
    }
    b = eval("String.fromCharCode(" + s.join(',') + ")"); //用fromCharCode()算出字符串
    return b; //返回被解密的字符串
  },
  // 将数字向上取整n位
  ceil(num, precision) {
    if (!precision || precision < 0) {
      precision = 0;
    }
    if (typeof num === 'number' && !isNaN(num)) {
      const factor = Math.pow(10, precision);
      return Math.ceil(num * factor) / factor;
    }
    return num
  },
  // 跳转到指定元素，传入元素的ref或者id，如this.$refs.hfdh
  maoDian(element, orderHeight) {
    let toTop = element.offsetTop
    if (orderHeight) {
      toTop = element.offsetTop + orderHeight
    }
    window.scrollTo(
     0,
     toTop
    );
  },
  // 判断是否是数组
  isArray(arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
  },
  // 深度克隆
  deepClone(obj) {
    // 对常见的“非”值，直接返回原来值
    if ([null, undefined, NaN, false].includes(obj)) return obj;
    if (typeof obj !== "object" && typeof obj !== 'function') {
      //原始类型直接返回
      return obj;
    }
    var o = this.isArray(obj) ? [] : {};
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        o[i] = typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
      }
    }
    return o;
  },
  downloadIamge(imgsrc, name) { // 下载图片地址和图片名
    var image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据
      var a = document.createElement('a') // 生成一个a元素
      var event = new MouseEvent('click') // 创建一个单击事件
      a.download = name || 'photo' // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgsrc
  },
  //订单预览数据处理
  orderPreviewDataDispose(data, optionList) {
    let arr = [];
    data.forEach((item) => {
      item.goods.forEach((items, index) => {
        items.checked = false;
        items.getOptionStatus = false;
        if (typeof items.detail === 'string') {
          items.detail = JSON.parse(items.detail);
        }
        if (typeof items.option === 'string') {
          items.option = JSON.parse(items.option);
        }
        items.mirrorLeftIcon = false;
        items.goodsLeftIcon = false;
        items.priceLeftIcon = false;
        items.optionsLeftIcon = false;
        if (items.preview_type === 'storage') {
          items.num = 1;
          items.pic = items.img_url;
          items.client_remark = items.note;
          if (items.option.length !== optionList.length) {
            if (items.option.length === 0) {
              items.optionChecked = false;
            }
            let newOptionList = this.deepClone(optionList);
            items.option.forEach((item1, optionIndex) => {
              newOptionList.forEach((optionItem, index) => {
                let count = 0;
                if (item1.checked) {
                  count++
                }
                items.optionChecked = !(optionIndex === items.option.length - 1 && count === 0);
                if (item1.id === optionItem.id || item1.name === optionItem.name) {
                  optionItem.num = item1.num;
                  optionItem.checked = item1.checked;
                } else if (item1.id == undefined && index === newOptionList.length - 1) {
                  newOptionList.push(item1);
                }
              })
            })
            items.option = newOptionList;
          } else {
            for (let i = 0; i < items.option.length; i++) {
              if (items.option[i].checked) {
                items.optionChecked = true;
                break;
              } else {
                items.optionChecked = false;
              }
            }
          }
        } else {
          items.for_sn = '';
          setTimeout(() => {
            items.option = this.deepClone(optionList);
          }, 0)
          if (items.id != undefined) {
            items.cart_id = items.id;
          } else {
            items.id = index;
          }
          items.optionChecked = false;
          if (items.order_detail_tag == undefined) {
            items.order_detail_tag = [];
          }
          if (items.pic == undefined) {
            items.pic = items.img_url
          }
        }
        arr.push(items)
      })
    })
    return arr
  },
  // 把价格两位小数后的文字缩小
  twoSmall(value, fontSize) {
    //console.log(value, value % 1 > 0);
    if (value % 1 > 0) {
      let arr = value.toString().split(",");
      return (
       "<font>" +
       arr[0] +
       ".</font><font style='font-size:'" + fontSize ? fontSize : '20' + "'px'>" +
        arr[1] +
        "</font>"
      );
    }
    return value;
  },
  matchingTag(val) {
    let name = '';
    let arr = [{
      value: 'certifiedFactory',
      name: '認定工場'
    }, {
      value: 'shipInToday',
      name: '当日出荷'
    }, {
      value: 'shipIn24Hours',
      name: '24時間以内出荷'
    }, {
      value: 'isOnePsaleFreePostage',
      name: '1点から仕入れ可能（送料無料）'
    }, {
      value: 'shipIn48Hours',
      name: '1点から仕入れ可能'
    }, {
      value: 'isOnePsale',
      name: '1点から仕入れ可能'
    }, {
      value: 'isOnePsaleFreePost',
      name: '24時間以内出荷率95％以下'
    }, {
      value: 'getRate24HLv1',
      name: '24時間以内出荷率95％以下'
    }, {
      value: 'getRate24HLv2',
      name: '24時間以内出荷率95％以上'
    }, {
      value: 'getRate24HLv3',
      name: '24時間以内出荷率99％以上'
    }, {
      value: 'getRate48HLv1',
      name: '48時間以内出荷率95％以下'
    }, {
      value: 'getRate48HLv2',
      name: '48時間以内出荷率95％以上'
    }, {
      value: 'getRate48HLv3',
      name: '48時間以内出荷率99％以上'
    }, {
      value: '1688Selection',
      name: '厳選'
    }, {
      value: 'noReason7DReturn',
      name: '7日以内無条件返品・交換'
    }];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].value == val) {
        name = arr[i].name
        break;
      }
    }
    if (name == '') {
      name = val
    }
    return name
  },
  // 欧元价格千分位
  EURNumSegmentation(num) {
    var result = '', counter = 0;
    num = (num || 0).toString();
    for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result = num.charAt(i) + result;
      if (!(counter % 3) && i != 0) {
        result = ',' + result;
      }
    }
    return result;
  },
  huilvqianf(Num) {
    // let num = String(Num).replace(/./, ',')
    return (String(Num).replace(/\./g, ','));
  },
  // 日元价格千分位
  yenNumSegmentation(Num) {
    let re = /\d(?=(?:\d{3})+\b)/g;
    // let num = String(Num).replace(/./, ',')
    let val = this.deepClone(Num);
    val = Number(val).toFixed(0)
    let num = (String(val).replace(/\./g, ','));
    num = String(num).replace(re, "$&,")
    return num;
  },
  // 将每个单词首字母转换成大写
  trunUpperCase(v) {
    return v.trim().toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase())
  },
  //西班牙时间
  timeE(v) {
    if (v) return v.substr(8, 2) + '-' + v.substr(5, 2) + '-' + v.substr(0, 4) + ' ' + v.substr(10)
  },
}
