import {apiAxios} from '../axios'
import Europess from '../wangZhi'

let Europe = Europess + '/client/'
export default {
  // 欧美站图片搜索商品
  imageSearchProduct: data => apiAxios('post', Europe + "imgSearchFengGoods", data),
  // 仅获取商品的规格属性及不同SKU单价
  getGoodsAttribute: data => apiAxios('post', Europe + "goodsAttribute", data),
  // 欧美站关键词搜索商品
  EuropeKeywordSearchProduct: data => apiAxios('post', Europe + "searchFengGoods", data),
  // 商品详情页面信息
  goodsParticulars: data => apiAxios('post', Europe + "goodsParticulars", data),
  //获取老版1688商品店铺名
  saleShopName: data => apiAxios('post', Europe + "saleShopName", data),
  //获取跨境1688运费
  goodsFreightPredict: data => apiAxios('post', Europe + "goodsFreightPredict", data),
  // 订单预览(提出前)
  orderPreview: data => apiAxios('post', Europe + "order.orderPreview", data),
  // 商品附加服务列表(option)
  optionList: data => apiAxios('post', Europe + "order.optionList", data),
  // tag（标签）列表
  getOrderTagList: data => apiAxios('post', Europe + "order.orderTagList", data),
  // 提出订单&&临时保存
  orderCreate: data => apiAxios('post', Europe + "order.orderCreate", data),
  // 订单列表
  orderList: data => apiAxios('post', Europe + "order.orderList", data),
  // 正式订单软删除
  orderCancel: data => apiAxios('post', Europe + "order.orderDelete", data),
  // 已取消订单硬删除
  orderRemove: data => apiAxios('post', Europe + "order.orderRemove", data),
  // 订单详情
  orderDetail: data => apiAxios('post', Europe + "order.orderDetail", data),
  // 订单预览从购物车追加商品数据展示
  orderAddCartGoods: data => apiAxios('post', Europe + "order.previewAddBeCart", data),
  // 订单预览从商品库追加商品数据展示
  orderAddInventoryGoods: data => apiAxios('post', Europe + "order.previewAddBeInventory", data),
  //查询某商品是否已收藏
  favoriteGoodsCheckIn: data => apiAxios('post', Europe + "favorite.goodsCheckIn", data),
  // 正式订单编辑(目前仅支持待付款订单)
  orderEdit: data => apiAxios('post', Europe + "order.orderEdit", data),
  // 订单支付界面数据
  orderPayment: data => apiAxios('post', Europe + "order.payData", data),
  // 订单合并支付界面数据
  orderMergePayData: data => apiAxios('post', Europe + "order.orderMergePayData", data),
  // 获取订单合并在库无的单子
  orderMergeStatus: data => apiAxios('post', Europe + "order.orderMergeStatus", data),
  // 批量付款修改在库无状态
  orderMergeUpdateStatus: data => apiAxios('post', Europe + "order.orderMergeUpdateStatus", data),
  // 获取国际物流支持的运输方式
  internationalLogisticsTransportation: data => apiAxios('post', Europe + "order.internationalLogisticsList", data),
  // 通过订单查询配送单概况
  onePorderOverview: data => apiAxios('post', Europe + "order.onePorderOverview", data),
  // 配送单支付界面数据
  storagePOrderPayment: data => apiAxios('post', Europe + "porder.payData", data),
  // 获取配送单合并支付界面数据
  porderMergePayData: data => apiAxios('post', Europe + "porder.porderMergePayData", data),
  //判断千速可否下载税金信息
  petitionStatus: data => apiAxios('post', Europe + "porder.petitionStatus", data),
  // 配送单支持使用的优惠券
  usableDiscount: data => apiAxios('post', Europe + "porder.usableDiscount", data),
  // 配送单装箱明细
  porderBoxDetail: data => apiAxios('post', Europe + "porder.porderBoxDetail", data),
  // 余额支付配送单
  storagePOrderBalancePayment: data => apiAxios('post', Europe + "porder.balancePayOrder", data),
  // 从普通仓库追加商品
  porderPreviewAddBeStockAuto: data => apiAxios('post', Europe + "porder.porderPreviewAddBeStockAuto", data),
  // 银行转账支付配送单
  storagePOrderBankPayment: data => apiAxios('post', Europe + "porder.bankPayOrder", data),
  // 配送单可选支付方式列表
  porderPayMethodList: data => apiAxios('post', Europe + "porder.payMethodList", data),
  // 配送单提出前预览
  porderSubmitPreview: data => apiAxios('post', Europe + "porder.porderPreview", data),
  // 替换商品
  replaceGoods: data => apiAxios('post', Europe + "order.replaceGoods", data),
  // 余额支付订单
  balancePayOrder: data => apiAxios('post', Europe + "order.balancePayOrder", data),
  // 可选支付方式列表
  payMethodList: data => apiAxios('post', Europe + "order.payMethodList", data),
  //银行转账支付订单
  bankPayOrder: data => apiAxios('post', Europe + "order.bankPayOrder", data),
  //订单金额变动明细
  orderAmountChangeDetail: data => apiAxios('post', Europe + "order.orderAmountChangeDetail", data),
  //查询单番商品标准手续费-费率
  orderGetServiceRate: data => apiAxios('post', Europe + "order.getServiceRate", data),
  //客户提交反馈(不校验登录信息的)
  addFeedbackUnLogin: data => apiAxios('post', Europe + "feedback.addFeedbackUnLogin", data),
  //图片搜索分销商品上传base64
  uploadImagePic: data => apiAxios('post', "https://api-landingpage.rakumart.cn/api/cross", data),
  //上传完的文件转Base64
  getBase64: data => apiAxios('post', Europess + "/plugin/wpi/getBase64", data),
  //商品价格趋势
  goodsPriceTrend: data => apiAxios('post', Europe + "goodsPriceTrend", data),
}
